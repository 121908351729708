import React from "react"
import Recetas from './Recetas'
import bizcocho from '../images/bizcocho.jpg'
import antiquo from '../images/antiquo.svg'

const Seccion1= ()=>{
  return(
    <div>
      <section>
        <h2>Seccion 1</h2>
        <div className="postre" >
          <Recetas />
        </div>
        <img src={bizcocho} alt="Bizcocho" />
        <img src={antiquo} alt="Antiquo" />
      </section>
    </div>
    )
}
export default Seccion1