import React from "react"
import {useState} from "react"

const Bizcocho={
    azucar: 100,
    yogurt: 2,
    huevos: 6
}

const Pastel={
    ...Bizcocho,
    nata: 250,
    fresas: 12,
    azucar: 150
}

const Recetas= ()=>{
    const [receta, setReceta ]=useState({})
    return(
            <div className="recetas">
                <h3>Receta</h3>
                <button onClick={()=>setReceta(Bizcocho)}>Bizcocho</button>
                <button onClick={()=>setReceta(Pastel)}>Pastel</button>
                <ul>
                    {Object.keys(receta).map((ingrediente)=>(
                        <li key={ingrediente}>
                            {ingrediente}: {receta[ingrediente]}
                        </li>
                    ))}
                </ul>
            </div>
        
    )
}

export default Recetas



console.log(Bizcocho)
console.log(Pastel)